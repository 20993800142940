<template>
  <div style="height: 100vh" class="d-flex flex-column">
    <div class="container flex-grow-1" v-if="profile">
      <div class="wrapper h-100 d-flex flex-column px-2">
        <Header title="付款資訊" backto="/" />
        <Menu :menu="menu" />
        <div class="mt-1 p-2" v-if="license.length">
          <span v-if="payment.length != 0">下次自動續約日期</span>
          <span v-else>到期日</span>
          <span>{{ format(parseISO(profile.license.endingTime), 'yyyy/MM/dd') }}</span>
        </div>
        <div v-if="payment.length == 0">
          <Notes text="您的會期已中斷，點數將於到期日重新計算。" icon="exclamation-circle" />
        </div>
        <div v-if="payment.length != 0">
          <div v-for="(item, key) in payment" :key="key">
            <div class="card-item p-2">
              <div class="d-flex flex-column justify-content-between h-100">
                <div class="card-item-num">{{ `XXXX-XXXX-XXXX-${item.cardLastFour}` }}</div>
                <div class="d-flex card-item-desc">
                  <span>{{ getCardTypeText(item.cardType) }}</span>
                  <span style="color: #76cc79" v-if="item.enabled">使用中</span>
                  <span v-else>已停用</span>
                </div>
              </div>
            </div>
            <PayModal
              v-if="payModalShow"
              :onClose="closePayModal"
              :status="'warning'"
              :cardId="item.id"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" v-show="payment.length == 20000" @click="$router.push('payment/order')">
      <Button name="重新綁卡" isHighlighted="true"></Button>
    </div>
    <PlanModal v-if="planModalShow" :onClose="closePlanModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseISO, format } from 'date-fns';
import { payment } from '@/mixins';
import Header from '@/components/Header.vue';
import Button from '@/elements/Button.vue';
import Notes from '@/elements/Notes.vue';
import Menu from '@/components/Menu.vue';
import PlanModal from '@/components/PlanModal.vue';
import PayModal from '@/components/PayModal.vue';

export default {
  mixins: [payment],
  computed: {
    ...mapState({
      profile: (state) => state.auth.profile,
      payment: (state) => state.payment.payment,
      license: (state) => state.payment.license,
      profileRequesting: (state) => state.auth.profileRequesting,
    }),
  },
  data() {
    return {
      planModalShow: false,
      payModalShow: false,
      menu: [
        {
          name: 'PAMO POINT 說明',
          id: 'plan',
          highlighted: true,
          clickEvent: true,
          notes: 'PAMO POINT 的點數說明與查詢',
          onClick: () => {
            this.planModalShow = true;
          },
        },
      ],
    };
  },
  components: {
    Header,
    Button,
    Menu,
    PlanModal,
    PayModal,
    Notes,
  },
  methods: {
    parseISO,
    format,
    getCardTypeText(cardType) {
      switch (cardType) {
        case '-1':
          return '未知的信用卡';
        case '1':
          return 'VISA 信用卡';
        case '2':
          return 'MasterCard 信用卡';
        case '3':
          return 'JCB 信用卡';
        case '4':
          return 'Union Pay 信用卡';
        case '5':
          return 'AMEX 信用卡';
        default:
          return '信用卡';
      }
    },
    // onDeleteClick(cardId) {
    //   this.deleteUserPayment({
    //     cardId,
    //     onSuccess: () => {
    //       this.$router.go();
    //     },
    //     onFailed: (err) => {
    //       switch (err.response.data.apiErrorCode) {
    //         case 'CARD_IS_ENABLED':
    //           alert('不能刪除正在使用中的卡。');
    //           break;
    //         default:
    //           alert('發生錯誤，請再試一次');
    //           break;
    //       }
    //     },
    //   });
    // },
    closePlanModal() {
      this.planModalShow = false;
    },
    closePayModal() {
      this.payModalShow = false;
    },
  },
  watch: {
    profile: {
      immediate: true,
      handler() {
        this.fetchUserPayment();
        this.fetchUserLicense();
      },
    },
  },
};
</script>

<style>
.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
}

.card-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  margin-bottom: 35px;
}

.card-item-num {
  font: normal normal normal 20px/24px Helvetica Neue;
  color: #ffffff;
}

.card-item-desc {
  font: normal normal bold 15px/18px Helvetica Neue;
  color: #6e6e6e;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
}

.card-item-delete {
  font: normal normal bold 15px/18px Helvetica Neue;
  color: #ff0045;
  cursor: pointer;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
